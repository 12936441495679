const firebaseConfig = {
  apiKey: "AIzaSyAaZSAsa9-7O_ffxqPPVbUsMZ13eDXH7TU",
  authDomain: "helios-9d435.firebaseapp.com",
  projectId: "helios-9d435",
  storageBucket: "helios-9d435.appspot.com",
  messagingSenderId: "768984637940",
  appId: "1:768984637940:web:90dee43f1fc4aeafbdd9a1",
  measurementId: "G-4FBRG6M1VB"
};

export { firebaseConfig };
